import { onBeforeMount, onBeforeUpdate, ref } from 'vue-demi'

import { getTabletMediaQuery } from '../utils'

export default function isMobile() {
  const isMobile = ref(true)

  function updateMobileStatus() {
    const mediaQuery = getTabletMediaQuery()

    isMobile.value = !mediaQuery.matches
  }

  onBeforeMount(() => {
    updateMobileStatus()

    window.addEventListener('resize', updateMobileStatus)
  })

  onBeforeUpdate(() => updateMobileStatus)

  return isMobile
}
