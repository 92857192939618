import { getTabletMediaQuery } from '../utils'

export default {
  data() {
    return {
      isMobile: true,
    }
  },
  beforeMount() {
    this.updateMobileStatus()

    window.addEventListener('resize', this.updateMobileStatus)
  },
  beforeUpdate() {
    this.updateMobileStatus()
  },
  methods: {
    updateMobileStatus() {
      const mediaQuery = getTabletMediaQuery()

      this.isMobile = !mediaQuery.matches
    },
  },
}
