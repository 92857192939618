import axios, {
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
  Method,
} from 'axios'
import urlcat, { ParamMap } from 'urlcat'

import { PaginationMetadata } from '@front/common/types'

export const GET = 'GET'
export const PUT = 'PUT'
export const POST = 'POST'
export const DELETE = 'DELETE'

interface RequestParams {
  method: Method
  url: string
  body?: any
  transformResponse?: AxiosResponseTransformer[]
  transformRequest?: AxiosRequestTransformer[]
  withCredentials?: boolean
  headers?: any
  signal?: AbortSignal
}

export type PaginatedResponse<T> = {
  data: {
    data: T[]
    metaData: PaginationMetadata
  }
}

export type Response<T = Record<string, unknown>> = {
  data: T
}

export function parseUrl(path: string, params?: ParamMap) {
  const customerId =
    // We need to detect an id in the url when we want to modify a client as Japhytos.
    new URLSearchParams(global?.location?.search).get('id') ||
    params?.customerId

  const url = process.env.API_URL || process.env.VUE_APP_API_URL

  return urlcat(url, path, {
    ...params,
    customerId,
  })
}

export default async function request<T>({
  method = GET,
  url,
  body,
  transformResponse = [],
  transformRequest = [],
  withCredentials = true,
  headers = {},
  signal = null,
}: RequestParams): Promise<T> {
  const config: AxiosRequestConfig = {
    method,
    url,
    data: body,
    signal,
    xsrfCookieName: 'JaphyAuth',
    transformRequest: [
      ...transformRequest,
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      ...transformResponse,
    ],
    withCredentials,
    headers,
  }

  try {
    return await axios.request(config)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    if (error.response) {
      // @TODO: Waiting same error response from our api
      const haveStructureErrors = error.response.data?.errors?.[0]?.message
      const errors = {
        httpCode: error.response.status,
        message: haveStructureErrors || 'An error has occurred',
        errorCode: error.response.data?.errors?.[0]?.code,
      }

      return Promise.reject(errors)
    }

    if (error.request) {
      return Promise.reject(error)
    }
    return Promise.reject(error.message)
  }
}
