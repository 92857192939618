var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.cardsWrapper},_vm._l((_vm.items),function(item){
var _obj, _obj$1;
return _c('div',{key:item.id},[(_vm.isMobile)?_c('ProductCard',{class:[
        _vm.$style.productCard,
        ( _obj = {}, _obj[_vm.$style.isSelected] = _vm.isItemSelected(item.id), _obj ) ],attrs:{"title":item.recipe.marketLabel},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('Packshot',{class:_vm.$style.cardImage,attrs:{"src":item.imageUrls.thumbnail}},[(item.isNew)?_c('Badge',[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n              "+_vm._s(_vm.$i18n('Nouveauté'))+"\n            ")])],1):_vm._e()],1)]},proxy:true},{key:"price",fn:function(){return [_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n          "+_vm._s(_vm.formatPrice(item.pricing.base.amount))+"\n        ")])]},proxy:true},{key:"subTitle",fn:function(){return [_vm._t("subTitle")]},proxy:true},{key:"quantity",fn:function(){return [_vm._t("quantity")]},proxy:true},{key:"content",fn:function(){return [_c('div',{class:_vm.$style.contentWrapper},[_c('div',{class:_vm.$style.itemInfo},[_c('Label',[_vm._v("\n              "+_vm._s(_vm.$i18n('{quantity} x {weight}', {
                  quantity: item.articles[0].packings[0].Size,
                  weight: _vm.formatQuantity(item.articles[0].weight),
                }))+"\n            ")]),_c('Button',{class:_vm.$style.link,attrs:{"appearance":"link","data-market":"recipe-product-more-details"},on:{"click":function($event){_vm.openModal(_vm.getModalName(item.id))}}},[_vm._v("\n              "+_vm._s(_vm.$i18n('En savoir plus'))+"\n            ")])],1),_c('GrowingControlledInputNumber',{class:_vm.$style.growingControlledInputNumber,attrs:{"is-loading":_vm.isLoading,"min":_vm.disableDelete ? 1 : 0,"value":item.quantity.base
                ? item.quantity.base / item.articles[0].packings[0].Size
                : 0},on:{"input":function (data) { return _vm.$emit(
                  'input',
                  _vm.updateItemQuantity(
                    item,
                    data * item.articles[0].packings[0].Size
                  )
                ); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var handleInput = ref.handleInput;
return [_c('TextInput',{class:_vm.$style.textInput,attrs:{"value":value,"disable-decimals":""},on:{"input":handleInput}})]}}],null,true)})],1)]},proxy:true}],null,true)}):_vm._e(),(!_vm.isMobile)?_c('HorizontalProductCard',{class:[
        _vm.$style.productCard,
        ( _obj$1 = {}, _obj$1[_vm.$style.isSelected] = _vm.isItemSelected(item.id), _obj$1 ) ],attrs:{"title":item.recipe.marketLabel},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('Packshot',{class:_vm.$style.cardImage,attrs:{"src":item.imageUrls.thumbnail}},[(item.isNew)?_c('Badge',[_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodySmallRegular}},[_vm._v("\n              "+_vm._s(_vm.$i18n('Nouveauté'))+"\n            ")])],1):_vm._e()],1)]},proxy:true},{key:"price",fn:function(){return [_c('Typography',{attrs:{"variant":_vm.TYPOGRAPHY_TYPES.bodyBold}},[_vm._v("\n          "+_vm._s(_vm.formatPrice(item.pricing.base.amount))+"\n        ")])]},proxy:true},{key:"subTitle",fn:function(){return [_vm._t("subTitle")]},proxy:true},{key:"quantity",fn:function(){return [_vm._t("quantity")]},proxy:true},{key:"content",fn:function(){return [_c('div',{class:_vm.$style.contentWrapper},[_c('div',{class:_vm.$style.itemInfo},[_c('Label',[_vm._v("\n              "+_vm._s(_vm.$i18n('{quantity} x {weight}', {
                  quantity: item.articles[0].packings[0].Size,
                  weight: _vm.formatQuantity(item.articles[0].weight),
                }))+"\n            ")]),_c('Button',{class:_vm.$style.link,attrs:{"appearance":"link","data-market":"recipe-product-more-details"},on:{"click":function($event){_vm.openModal(_vm.getModalName(item.id))}}},[_vm._v("\n              "+_vm._s(_vm.$i18n('En savoir plus'))+"\n            ")])],1),_c('GrowingControlledInputNumber',{class:_vm.$style.growingControlledInputNumber,attrs:{"value":item.quantity.base
                ? item.quantity.base / item.articles[0].packings[0].Size
                : 0,"min":_vm.disableDelete ? 1 : 0,"is-loading":_vm.isLoading},on:{"input":function (data) { return _vm.$emit(
                  'input',
                  _vm.updateItemQuantity(
                    item,
                    data * item.articles[0].packings[0].Size
                  )
                ); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var value = ref.value;
                var handleInput = ref.handleInput;
return [_c('TextInput',{class:_vm.$style.textInput,attrs:{"value":value,"disable-decimals":""},on:{"input":handleInput}})]}}],null,true)})],1)]},proxy:true}],null,true)}):_vm._e(),_c('Modal',{attrs:{"name":_vm.getModalName(item.id)}},[_c('ItemDetails',{attrs:{"pet":_vm.pet,"translation-keys":item.translationKeys,"recipe":item.recipe,"image-urls":item.imageUrls,"is-kibbles":_vm.isKibblesItem(item.type)}})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }